import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Menus } from './NavBar'
import { PrimaryButton } from '../common/BookingButton'
import BgImage from '../../assets/images/promaid/bg-disinfection.svg'
import BgImage2 from '../../assets/images/promaid/bg-disinfection-moblie.svg'
import CorrectIcon from '../../assets/images/promaid/icons/correct.svg'
import Line from '../../assets/images/promaid/icons/line-button.svg'
import Slider from '../common/SliderComponent'
import PacketsCard from '../common/PacketsCard'
import SpacialPacket from '../../assets/images/promaid/special-promo.jpg'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`

const Title = styled.h1`
  font-weight: 500;
  font-size: 50px;
  line-height: 65px;
  text-align: right;
  color: #0179ba;
  @media (max-width: 1024px) {
    font-size: 42px;
    line-height: 60px;
  }
  @media (max-width: 768px) {
    line-height: 50px;
    font-size: 36px;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 30px;
  }
`

const TitlePacket = styled.h3`
  font-size: 30px;
  line-height: 1.2;
  font-weight: 400;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 1.5;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.5;
  }
`

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  text-align: right;
  color: #61b2cd;
  @media (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    line-height: 20px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const SubtitleList = styled.p`
  font-style: lighter;
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  text-align: right;
  color: #5c5c69;
  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    line-height: 20px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const SubText = styled.p`
  font-style: lighter;
  font-weight: 300;
  font-size: 18px;
  line-height: 38px;
  text-align: right;
  color: #5c5c69;
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    line-height: 20px;
    font-size: 12px;
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
`

const Card = styled.div`
  align-self: center;
  height: 100%;
  width: 300px;
  padding: 5px;
`

const BgImageDiv = styled.div`
  position: absolute;
  width: 100vw;
  @media (max-width: 1024px) {
  }
  @media (max-width: 1024px) {
  }
`

const packets = [
  {
    title: '',
    name: 'บริการฉีดพ่นฆ่าเชื้อ',
    description: '',
    price: '2,500',
    logo: SpacialPacket,
    theme: 'special-promo',
    detail: 'เริ่มต้นเพียง'
  }
]

const Disinfection: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectCard, setSelectCard] = useState(packets[0])
  return (
    <>
      <div id={Menus.DISINFECTION} className="lg:pt-4" />
      <Container>
        <div className="relative w-full bg-primary">
          <div className="relative w-full pb-10">
            <div className="static w-full">
              <BgImageDiv className="flex flex-row justify-between w-full">
                <img className="top-0 hidden sm:flex" src={BgImage} alt="cover-desktop" />
                <div className="right-0 w-1/2 bg-blue-400" />
              </BgImageDiv>
              <img className="absolute flex w-full h-auto sm:hidden" src={BgImage2} alt="cover-mobile" />
            </div>
            {/* destop */}
            <div className="relative justify-center hidden w-full pt-4 lg:pt-8 xl:pt-24 md:flex">
              <div className="flex flex-row">
                <div className="flex items-end mx-4 lg:mx-8 md:-mt-2 lg:-mt-0">
                  <PrimaryButton
                    onClick={() => {
                      window.open('https://bit.ly/2Pln3bV', '_blank')
                    }}
                    className="button-line "
                  >
                    <div className="flex flex-row items-center justify-between -my-2 lg:-my-1 button-line">
                      <p className="text-lg button-line lg:text-2xl">จองบริการฉีดพ่นฆ่าเชื้อ </p>
                      <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
                        <img src={Line} alt="line" />
                      </div>
                    </div>
                  </PrimaryButton>
                </div>
                <div className="pr-4">
                  <div className="flex justify-center pt-2 sm:pt-0">
                    <Title className="leading-none text-primary sm:mb-1 xl:mb-2">
                      DISINFECTION SERVICE <br /> บริการฉีดพ่นฆ่าเชื้อโควิด-19
                    </Title>
                  </div>
                  <div className="flex justify-end pt-2">
                    <Subtitle className="leading-none text-primary sm:mb-1 xl:mb-2">
                      กำจัดเชื้อไวรัสโควิด-19 ให้อยู่หมัด ด้วยบริการ <br /> ฉีดพ่นฆ่าเชื้อ ProMaid
                    </Subtitle>
                  </div>
                  <div className="flex-col items-center hidden pt-1 lg:pt-8 md:flex">
                    <div className="flex flex-col items-start ml-16 text-gray-200 lg:ml-0">
                      <div className="flex flex-row p-1 -mb-2 leading-none lg:p-2">
                        <img className="relative top-0 right-0 flex pr-4 h-3/4 lg:h-full" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList>ฆ่าเชื้อโรค ไวรัส แบคทีเรีย 99%</SubtitleList>
                      </div>
                      <div className="flex flex-row p-1 -mb-2 leading-none lg:p-2">
                        <img className="relative top-0 right-0 flex pr-4 h-3/4 lg:h-full" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList>ใช้น้ำยาฆ่าเชื้อ ประสิทธิภาพสูง</SubtitleList>
                      </div>
                      <div className="flex flex-row p-1 -mb-2 leading-none lg:p-2">
                        <img className="relative top-0 right-0 flex pr-4 h-3/4 lg:h-full" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList>อยู่ได้นานถึง 1 เดือน</SubtitleList>
                      </div>
                      <div className="flex flex-row p-1 -mb-2 leading-none lg:p-2">
                        <img className="relative top-0 right-0 invisible pr-4 h-3/4 lg:h-full" src={CorrectIcon} alt="correct-icon" />
                        <SubText>*สอบถามเพิ่มเติม</SubText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* moblie */}
            <div className="relative flex justify-end w-full pt-0 sm:pt-2 md:pt-4 lg:pt-24 md:hidden">
              <div className="flex flex-col pt-6 md:pt-8">
                <div className="pr-4">
                  <div className="flex flex-col justify-end">
                    <Title>DISINFECTION SERVICE</Title>
                    <Title className="-mt-1">บริการฉีดพ่นฆ่าเชื้อโควิด-19</Title>
                  </div>
                  <div className="flex justify-end pt-1 sm:pt-2">
                    <Subtitle className="leading-none text-primary sm:mb-1 xl:mb-2">
                      กำจัดเชื้อไวรัสโควิด-19 ให้อยู่หมัด <br />
                      ด้วยบริการ ฉีดพ่นฆ่าเชื้อ ProMaid
                    </Subtitle>
                  </div>
                  <div className="flex justify-end max-h-full p-2 -mb-1 sm:-mb-0">
                    <div className="flex flex-col items-start text-gray-200">
                      <div className="flex flex-row -mt-1">
                        <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList className="">ฆ่าเชื้อโรค ไวรัส แบคทีเรีย 99%</SubtitleList>
                      </div>
                      <div className="flex flex-row -mt-1">
                        <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList className="">ใช้น้ำยาฆ่าเชื้อ ประสิทธิภาพสูง</SubtitleList>
                      </div>
                      <div className="flex flex-row -mt-1">
                        <img className="relative top-0 right-0 flex w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                        <SubtitleList className="">อยู่ได้นานถึง 1 เดือน</SubtitleList>
                      </div>
                      <div className="flex flex-row -mt-1">
                        <img className="relative top-0 right-0 invisible w-6 pr-2" src={CorrectIcon} alt="correct-icon" />
                        <SubText className="">*สอบถามเพิ่มเติม</SubText>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative flex items-end mx-8">
                  <PrimaryButton
                    onClick={() => {
                      window.open('https://bit.ly/2Pln3bV', '_blank')
                    }}
                    className="button-line "
                  >
                    <div className="flex flex-row items-center justify-between -my-3 sm:-my-2 md:-my-1 button-line">
                      <p className="text-sm button-line sm:text-2xl">จองบริการฉีดพ่นฆ่าเชื้อ </p>
                      <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
                        <img src={Line} alt="line" />
                      </div>
                    </div>
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="container p-4">
              <div className="container w-full px-4 pt-0 md:pt-4 xl:pt-6">
                <div className="w-full p-4">
                  <div className="w-full pb-16 xl:px-48">
                    <TitlePacket className="flex justify-center py-6 text-lg text-center text-white lg:text-2xl xl:py-10">
                      แพ็กเกจบริการฉีดพ่นฆ่าเชื้อ
                    </TitlePacket>
                    <div className="flex justify-center w-full button-line">
                      <div className="flex justify-center w-full button-line">
                        <div className="flex items-center">
                          {packets.map(p => (
                            <Card
                              className="button-line"
                              key={p.name}
                              onClick={() => {
                                setSelectCard(p)
                                setIsOpen(true)
                                window.open('https://bit.ly/2Pln3bV', '__blank')
                              }}
                            >
                              <div className="mt-4">
                                <PacketsCard packet={{ ...p }} />
                              </div>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex justify-center -mt-8 sm:-mt-4">
                    <div className="relative flex">
                      <PrimaryButton
                        onClick={() => {
                          window.open('https://bit.ly/2Pln3bV', '_blank')
                        }}
                        className="button-line "
                      >
                        <div className="flex flex-row items-center justify-between -my-1 md:-my-1 button-line">
                          <p className="text-lg button-line lg:text-xl">จองเลย </p>
                          <div className="flex justify-end w-10 h-10 pl-2 button-line md:w-auto md:h-auto">
                            <img src={Line} alt="line" />
                          </div>
                        </div>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
                <p className="py-6 text-sm text-center text-white md:text-base lg:text-xl lg:py-10 xl:text-3xl">
                  ปลอดภัยกว่า ด้วยน้ำยาฆ่าเชื้อมาตรฐานระดับสากล
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Disinfection
